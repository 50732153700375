/* Universal Selector */
/* * {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: black;
  background-color: #fff;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
} */

.container {
  max-width: 750px;
  display: inline-flex;
  /* flex-wrap: wrap; */
  /* margin-top: 40px; */
  /* margin-bottom: 40px; */
  /* margin-left: 10%; */
  /* margin-right: 10%; */
  margin: 40px;
  flex-flow: column wrap;
  justify-content: center;
}

.container a {
  cursor: pointer;
  max-height: 250px;
  max-width: 300px;
}

.project-img:hover {
  cursor: pointer;
}

.project-img {
  max-height: 200px;
  max-width: 275px;
  border-radius: 20px;
  margin: 20px 5% 20px 5%;
  box-shadow: 10px 10px 5px rgba(128, 128, 128, 0.281);
}

.title-repo {
  display: flex;
  align-content: center;
  align-items: center;
  max-width: 300px;
  margin: 0px 10% 0px 10%;
}

.h2-project {
  font-size: 18px;
}

.repo-btn {
  max-width: 30px;
  max-height: 30px;
}

.repo-btn:hover {
  opacity: 0.6;
}

.Proj-props {
  display: flex;
  flex-wrap: wrap;
  margin: 0 10% 0 10%;
  justify-content: center;
}

/* Universal Selector */
/* * {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgb(0, 0, 0);
  background-color: #fff;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
} */

a {
  font-size: 14px;
  text-decoration: none;
  padding: 0px 15px;
}

.navbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-left: 20vw;
  margin-right: 20vw;
  padding-top: 10px;
}

nav a {
  transition: all 0.3s ease 0s;
}
nav a:hover {
  transition: all 0.3s ease 0s;
  color: rgb(82, 71, 71);
  font-weight: bold;
}

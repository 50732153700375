/* Universal Selector */
/* * {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: black;
  background-color: #fff;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
} */

.contact-format {
  display: flex;
  flex-wrap: wrap;
  margin-top: 20%;
  justify-content: center;
}
/* 
.contact-me-link {
  max-width: auto;
  max-height: auto;
} */

.contact-me-link img {
  max-width: 50px;
  max-height: 50px;
}

.email-link {
  display: flex;
  font-size: 18px;
  font-weight: bold;
  justify-content: center;
  margin-bottom: 50px;
  margin-top: 25px;
}
.email-link:hover {
  cursor: pointer;
  text-decoration: underline;
}

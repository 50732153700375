/* Universal Selector */
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: black;
  background-color: #fff;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.intro-h2 {
  font-size: 28px;
  line-height: 1.5;
  margin-left: 22%;
  margin-right: 22%;
}

.p-intro {
  font-size: 14px;
  line-height: 1.5;
  max-width: 60%;
  margin-left: 22%;
  margin-right: 22%;
  padding-bottom: 25px;
  justify-content: center;
}

.img-intro {
  max-width: 17.5%;
  display: inline-flex;
  border-radius: 10px;
  margin-top: 5%;
}

.gap {
  margin-bottom: 15px;
}

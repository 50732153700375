/* Universal Selector */
/* * {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: black;
  background-color: #fff;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
} */

.p-footer {
  color: #fff;
  background-color: black;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  font-size: 15px;
  height: 25px;
  text-align: center;
  text-indent: 0;
}
